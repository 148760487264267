<template>
    <SelectGroup id="billingState" v-model="stateSelected" name="state" displayName="State" :options="stateOptions" @input="handleInput" ref="state" :error="error"/>
</template>

<script>
import axios from "axios";
import { authHeader } from "../../helpers/auth-header";
import SelectGroup from "@/components/forms/select-group";

export default {
    name: "state-group",
    components: {
        SelectGroup,
    },
    data() {
        return {
            stateOptions: [],
            stateSelected: '',
        }
    },
    props: {
        error: [],
        stateValue: {}
    },
    methods: {
        handleInput() {
            this.$emit('input', this.stateSelected);
        }
    },
    async created() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/state`, {
            headers: authHeader(),  
        }).catch( function () {
            alert("Fail to Load State Options");
        });

        if (result.data.code === 'success') {
            result.data.data.forEach((e) => {
                this.stateOptions.push({"name": e.name, "value": e.id});
            });
        }
    },
    mounted() {
        if (this.stateValue !== '') {
            this.stateSelected = this.stateValue;
        }
    }
    

}
</script>